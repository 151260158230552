import React from "react"
import Grid4 from "../Layout/Grids/Grid4"
import ModalProdutos from "../Modal/ModalProdutos"

const Card = ({src, alt, serie, atual }) => {
    const [modal, setModal] = React.useState(false)
    const [current, setCurrent] = React.useState(null)
    const [series, setSeries] = React.useState(null)  
  
    function handleModal() {
      setModal(!modal)    
    }
  
    React.useEffect( () => {
      setCurrent(atual)
      setSeries(serie)
    },[atual, serie])
  
    React.useEffect( () => {
      let body = document.body
      if (modal) {
        body.style.overflowY = "hidden"
      }else {
        body.style.overflowY = "auto"
      }
    },[modal])
  
    return <Grid4 className='card-produto'>
      <div onClick={handleModal}>
        <img src={src} alt={alt}/>
      </div>
      {modal && <ModalProdutos current={current} 
        serie={series} 
        onClick={handleModal} 
        modal={modal} />
      }
    </Grid4>
  }
export default Card