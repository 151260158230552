import Sobre from '../components/Pages/Sobre'
import Produto from '../components/Pages/Produto'
import Contato from "../components/Pages/Contato"
import Blog from '../components/Pages/Blog'

export const _rotas = [
  {id:0,path:'produtos',element:<Produto />,link:'produtos'},
  {id:1,path:'sobre', element: <Sobre />, link:'sobre'},
  {id:2,path:'blog', element: <Blog />, link:'blog'},
  {id:3,path:'contato', element: <Contato />, link:'contato'},
  
]

export const _subRotas = [
  {id:0,path:"cortinas", to:'produtos/cortinas', link:'cortinas', element:<Produto />},
  {id:1,path:"persianas", to:'produtos/persianas', link:'persianas', element:<Produto />},    
  {id:2,path:"papel de parede", to:'produtos/papeis-de-parede', link:'papeis de parede', element:<Produto />},    
] 
