import React from 'react'
import './Cortinas.css'
import {persianas} from '../../Apis/ProdutosApi'
import H1 from '../Common/Typograph/H1'
import H2 from '../Common/Typograph/H2'
import P from '../Common/Typograph/P'
import Card from '../Common/Card/Card'
import ContainerFlex from '../Common/Layout/Container/ContainerFlex'
import Sections from '../Sections/Sections'

const Persianas = () => {
    const [produtos, setProdutos] = React.useState(null)
    const [title, setTitle] = React.useState(String)
    const [text, setText] = React.useState(String)

    React.useEffect( () => {
        //carregamento dos estados com os dados da pagina.
        const {pathname} = window.location
        if (pathname === '/produtos/persianas') {
            setProdutos(persianas)
            setTitle('Persianas')
            setText('Trabalhamos com as Melhores linhas de Persianas do Mercado.Neutra e versátil, a persiana auxilia no controle da iluminação, traz privacidade e conforto, além de ter um grande impacto na decoração dos ambientes.')
        }
    },[produtos])
    return <>
        <section className='produto-header'>
            <H1 className='text-center'>{title}</H1>
            <div className="container flex-center">                
                <P className='text-center'>{text}</P>
            </div> 
        </section>
        <Sections>
        {(produtos !== null && produtos.map( item => {
            return <div key={item.id} className='cortinas-produtos'>
            <H2 className='container'>{item.title}</H2>
            <ContainerFlex key={item.id} className='wrap'>
                {item['serie'].map( card => {
                return <Card key={card.id}
                    serie={item['serie']}
                    atual={card}
                    src={card.src} 
                    alt={card.alt} />
                })}            
            </ContainerFlex>
            <hr className='container' 
            style={{marginBottom:'80px'}}/>
            </div>
        }))}  
        </Sections> 
    </>
}
export default Persianas