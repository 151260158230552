// Cortinas ilhós ja foi
import cortinaIlhos01  from '../components/assets/produtos/cortinas/ilhos/ci01.jpg'
import cortinaIlhos02  from '../components/assets/produtos/cortinas/ilhos/ci02.jpg'
import cortinaIlhos03  from '../components/assets/produtos/cortinas/ilhos/ci03.jpg'
import cortinaIlhos04  from '../components/assets/produtos/cortinas/ilhos/ci04.jpg'
import cortinaIlhos05  from '../components/assets/produtos/cortinas/ilhos/ci05.jpg'
import cortinaIlhos06  from '../components/assets/produtos/cortinas/ilhos/ci06.jpg'

//Cortinas prega-americana
import cortinaPregaA01 from '../components/assets/produtos/cortinas/americana/cpa01.jpg'
import cortinaPregaA02 from '../components/assets/produtos/cortinas/americana/cpa02.jpg'
import cortinaPregaA03 from '../components/assets/produtos/cortinas/americana/cpa03.jpg'
import cortinaPregaA04 from '../components/assets/produtos/cortinas/americana/cpa04.jpg'
import cortinaPregaA05 from '../components/assets/produtos/cortinas/americana/cpa05.jpg'
import cortinaPregaA06 from '../components/assets/produtos/cortinas/americana/cpa06.jpg'

//cortina prega-macho ja foi
import cortinaPregaM01 from '../components/assets/produtos/cortinas/macho/cpm01.jpg'
import cortinaPregaM02 from '../components/assets/produtos/cortinas/macho/cpm02.jpg'
import cortinaPregaM03 from '../components/assets/produtos/cortinas/macho/cpm03.jpg'
import cortinaPregaM04 from '../components/assets/produtos/cortinas/macho/cpm04.jpg'
import cortinaPregaM05 from '../components/assets/produtos/cortinas/macho/cpm05.jpg'
import cortinaPregaM06 from '../components/assets/produtos/cortinas/macho/cpm06.jpg'

//cortina dualvision ja foi
import cortinaDV01 from '../components/assets/produtos/cortinas/dualvision/cdv01.jpg'
import cortinaDV02 from '../components/assets/produtos/cortinas/dualvision/cdv02.jpg'
import cortinaDV03 from '../components/assets/produtos/cortinas/dualvision/cdv03.jpg'
import cortinaDV04 from '../components/assets/produtos/cortinas/dualvision/cdv04.jpg'
import cortinaDV05 from '../components/assets/produtos/cortinas/dualvision/cdv05.jpg'
import cortinaDV06 from '../components/assets/produtos/cortinas/dualvision/cdv06.jpg'

//cortina Painéis ja foi
import cortinaP01 from '../components/assets/produtos/cortinas/painel/cp01.jpg'
import cortinaP02 from '../components/assets/produtos/cortinas/painel/cp02.jpg'
import cortinaP03 from '../components/assets/produtos/cortinas/painel/cp03.jpg'
import cortinaP04 from '../components/assets/produtos/cortinas/painel/cp04.jpg'
import cortinaP05 from '../components/assets/produtos/cortinas/painel/cp05.jpg'
import cortinaP06 from '../components/assets/produtos/cortinas/painel/cp06.jpg'

//Cortinas Pliassa e Celular ja foi
import cortinaPliasa01 from '../components/assets/produtos/cortinas/pliassa/cpc01.jpg'
import cortinaPliasa02 from '../components/assets/produtos/cortinas/pliassa/cpc02.jpg'
import cortinaPliasa03 from '../components/assets/produtos/cortinas/pliassa/cpc03.jpg'
import cortinaPliasa04 from '../components/assets/produtos/cortinas/pliassa/cpc04.jpg'
import cortinaPliasa05 from '../components/assets/produtos/cortinas/pliassa/cpc05.jpg'
import cortinaPliasa06 from '../components/assets/produtos/cortinas/pliassa/cpc06.jpg'

//Cortinas Rolô ja foi
import cortinaRolo01 from '../components/assets/produtos/cortinas/rolo/cr01.jpg'
import cortinaRolo02 from '../components/assets/produtos/cortinas/rolo/cr02.jpg'
import cortinaRolo03 from '../components/assets/produtos/cortinas/rolo/cr03.jpg'
import cortinaRolo04 from '../components/assets/produtos/cortinas/rolo/cr04.jpg'
import cortinaRolo05 from '../components/assets/produtos/cortinas/rolo/cr05.jpg'
import cortinaRolo06 from '../components/assets/produtos/cortinas/rolo/cr06.jpg'

//Cortinas Romana ja foi
import cortinaRomana01 from '../components/assets/produtos/cortinas/romana/cr1.jpg'
import cortinaRomana02 from '../components/assets/produtos/cortinas/romana/cr2.jpg'
import cortinaRomana03 from '../components/assets/produtos/cortinas/romana/cr3.jpg'
import cortinaRomana04 from '../components/assets/produtos/cortinas/romana/cr4.jpg'
import cortinaRomana05 from '../components/assets/produtos/cortinas/romana/cr5.jpg'
import cortinaRomana06 from '../components/assets/produtos/cortinas/romana/cr6.jpg'

//Cortinas Silonew
import cortinaSilonew01 from '../components/assets/produtos/cortinas/silonew/csn01.jpg'
import cortinaSilonew02 from '../components/assets/produtos/cortinas/silonew/csn02.jpg'
import cortinaSilonew03 from '../components/assets/produtos/cortinas/silonew/csn03.jpg'
import cortinaSilonew04 from '../components/assets/produtos/cortinas/silonew/csn04.jpg'
import cortinaSilonew05 from '../components/assets/produtos/cortinas/silonew/csn05.jpg'
import cortinaSilonew06 from '../components/assets/produtos/cortinas/silonew/csn06.jpg'

//Persianas horizontal de aluminio
import persianaHA01 from '../components/assets/produtos/persianas/aluminio/pha01.jpg'
import persianaHA02 from '../components/assets/produtos/persianas/aluminio/pha02.jpg'
import persianaHA03 from '../components/assets/produtos/persianas/aluminio/pha03.jpg'
import persianaHA04 from '../components/assets/produtos/persianas/aluminio/pha04.jpg'
import persianaHA05 from '../components/assets/produtos/persianas/aluminio/pha05.jpg'
import persianaHA06 from '../components/assets/produtos/persianas/aluminio/pha06.jpg'

//Persianas de madeira
import persianaM01 from '../components/assets/produtos/persianas/madeira/pm01.jpg'
import persianaM02 from '../components/assets/produtos/persianas/madeira/pm02.jpg'
import persianaM03 from '../components/assets/produtos/persianas/madeira/pm03.jpg'
import persianaM04 from '../components/assets/produtos/persianas/madeira/pm04.jpg'
import persianaM05 from '../components/assets/produtos/persianas/madeira/pm05.jpg'
import persianaM06 from '../components/assets/produtos/persianas/madeira/pm06.jpg'

//Persianas Verticais
import persianaV01 from '../components/assets/produtos/persianas/verticais/pvt01.jpg'
import persianaV02 from '../components/assets/produtos/persianas/verticais/pvt02.jpg'
import persianaV03 from '../components/assets/produtos/persianas/verticais/pvt03.jpg'
import persianaV04 from '../components/assets/produtos/persianas/verticais/pvt04.jpg'
import persianaV05 from '../components/assets/produtos/persianas/verticais/pvt05.jpg'
import persianaV06 from '../components/assets/produtos/persianas/verticais/pvt06.jpg'

//Papeis de Parede
import pp from '../components/assets/produtos/papeis-de-parede/foto.jpg'



export const cortinas = [
    {
        id:0,
        title:'cortina ilhós',
        serie:[
            {id:0, src:cortinaIlhos01, alt:'cortina ilhós'},
            {id:1, src:cortinaIlhos02, alt:'cortina ilhós'},
            {id:2, src:cortinaIlhos03, alt:'cortina ilhós'},
            {id:3, src:cortinaIlhos04, alt:'cortina ilhós'},
            {id:4, src:cortinaIlhos05, alt:'cortina ilhós'},
            {id:5, src:cortinaIlhos06, alt:'cortina ilhós'}
        ]
    },
    {
        id:1,
        title:'cortina Prega-Americana',
        serie:[
            {id:0, src:cortinaPregaA01, alt:'cortina Prega Americana'},
            {id:1, src:cortinaPregaA02, alt:'cortina Prega Americana'},
            {id:2, src:cortinaPregaA03, alt:'cortina Prega Americana'},
            {id:3, src:cortinaPregaA04, alt:'cortina Prega Americana'},
            {id:4, src:cortinaPregaA05, alt:'cortina Prega Americana'},
            {id:5, src:cortinaPregaA06, alt:'cortina Prega Americana'},
        ]
    },
    {
        id:2,
        title:'cortina Prega-Macho',
        serie:[
            {id:0, src:cortinaPregaM01, alt:'cortina Prega Macho'},
            {id:1, src:cortinaPregaM02, alt:'cortina Prega Macho'},
            {id:2, src:cortinaPregaM03, alt:'cortina Prega Macho'},
            {id:3, src:cortinaPregaM04, alt:'cortina Prega Macho'},
            {id:4, src:cortinaPregaM05, alt:'cortina Prega Macho'},
            {id:5, src:cortinaPregaM06, alt:'cortina Prega Macho'},
        ]
    },
    {
        id:3,
        title:'cortina Dual Vision',
        serie:[
            {id:0, src:cortinaDV01, alt:'cortina Dual Vision'},
            {id:1, src:cortinaDV02, alt:'cortina Dual Vision'},
            {id:2, src:cortinaDV03, alt:'cortina Dual Vision'},
            {id:3, src:cortinaDV04, alt:'cortina Dual Vision'},
            {id:4, src:cortinaDV05, alt:'cortina Dual Vision'},
            {id:5, src:cortinaDV06, alt:'cortina Dual Vision'},
        ]
    },
    {
        id:4,
        title:'cortina Painéis',
        serie:[
            {id:0, src:cortinaP01, alt:'cortina Painéis'},
            {id:1, src:cortinaP02, alt:'cortina Painéis'},
            {id:2, src:cortinaP03, alt:'cortina Painéis'},
            {id:3, src:cortinaP04, alt:'cortina Painéis'},
            {id:4, src:cortinaP05, alt:'cortina Painéis'},
            {id:5, src:cortinaP06, alt:'cortina Painéis'},
        ]
    },
    {
        id:5,
        title:'cortina Pliasa e Celular',
        serie:[
            {id:0, src:cortinaPliasa01, alt:'cortina Pliasa e Celular'},
            {id:1, src:cortinaPliasa02, alt:'cortina Pliasa e Celular'},
            {id:2, src:cortinaPliasa03, alt:'cortina Pliasa e Celular'},
            {id:3, src:cortinaPliasa04, alt:'cortina Pliasa e Celular'},
            {id:4, src:cortinaPliasa05, alt:'cortina Pliasa e Celular'},
            {id:5, src:cortinaPliasa06, alt:'cortina Pliasa e Celular'},
        ]
    },
    {
        id:6,
        title:'cortina Rolô',
        serie:[
            {id:0, src:cortinaRolo01, alt:'cortina Rolô'},
            {id:1, src:cortinaRolo02, alt:'cortina Rolô'},
            {id:2, src:cortinaRolo03, alt:'cortina Rolô'},
            {id:3, src:cortinaRolo04, alt:'cortina Rolô'},
            {id:4, src:cortinaRolo05, alt:'cortina Rolô'},
            {id:5, src:cortinaRolo06, alt:'cortina Rolô'},
        ]
    },
    {
        id:7,
        title:'cortina Romana',
        serie:[
            {id:0, src:cortinaRomana01, alt:'cortina Romana'},
            {id:1, src:cortinaRomana02, alt:'cortina Romana'},
            {id:2, src:cortinaRomana03, alt:'cortina Romana'},
            {id:3, src:cortinaRomana04, alt:'cortina Romana'},
            {id:4, src:cortinaRomana05, alt:'cortina Romana'},
            {id:5, src:cortinaRomana06, alt:'cortina Romana'},
        ]
    },
    {
        id:8,
        title:'cortina Silonew',
        serie:[
            {id:0, src:cortinaSilonew01, alt:'cortina Silonew'},
            {id:1, src:cortinaSilonew02, alt:'cortina Silonew'},
            {id:2, src:cortinaSilonew03, alt:'cortina Silonew'},
            {id:3, src:cortinaSilonew04, alt:'cortina Silonew'},
            {id:4, src:cortinaSilonew05, alt:'cortina Silonew'},
            {id:5, src:cortinaSilonew06, alt:'cortina Silonew'},
        ]
    },

]

export const persianas = [
    {
        id:0,
        title:'persiana Horizontal de Alumínio',
        serie:[
            {id:0, src:persianaHA01, alt:'Persiana Horizontal de Alumínio'},
            {id:1, src:persianaHA02, alt:'Persiana Horizontal de Alumínio'},
            {id:2, src:persianaHA03, alt:'Persiana Horizontal de Alumínio'},
            {id:3, src:persianaHA04, alt:'Persiana Horizontal de Alumínio'},
            {id:4, src:persianaHA05, alt:'Persiana Horizontal de Alumínio'},
            {id:5, src:persianaHA06, alt:'Persiana Horizontal de Alumínio'},
        ]
    },
    {
        id:1,
        title:'Persiana de Madeira',
        serie:[
            {id:0, src:persianaM01, alt:'Persiana de Madeira'},
            {id:1, src:persianaM02, alt:'Persiana de Madeira'},
            {id:2, src:persianaM03, alt:'Persiana de Madeira'},
            {id:3, src:persianaM04, alt:'Persiana de Madeira'},
            {id:4, src:persianaM05, alt:'Persiana de Madeira'},
            {id:5, src:persianaM06, alt:'Persiana de Madeira'},
        ]
    },
    {
        id:2,
        title:'Persiana Vertical',
        serie:[
            {id:0, src:persianaV01, alt:'Persiana Vertical'},
            {id:1, src:persianaV02, alt:'Persiana Vertical'},
            {id:2, src:persianaV03, alt:'Persiana Vertical'},
            {id:3, src:persianaV04, alt:'Persiana Vertical'},
            {id:4, src:persianaV05, alt:'Persiana Vertical'},
            {id:5, src:persianaV06, alt:'Persiana Vertical'},
        ]
    },    
    
]

export const papeisParede = [
    {
        id:0,
        title:'Papeis de Parede',
        serie:[
            {id:0, src:pp, alt:'papeis de parede'},
            {id:1, src:pp, alt:'papeis de parede'},
            {id:2, src:pp, alt:'papeis de parede'},
            {id:3, src:pp, alt:'papeis de parede'},
            {id:4, src:pp, alt:'papeis de parede'},
            {id:5, src:pp, alt:'papeis de parede'},
        ]
    }
]

// export const papeisParede = [
//     {
//         id:0,
//         title:'cortina-dualvision',
//         serie:[
//             {id:0, src:parede01, alt:'cortina dual-vision'},
//             {id:1, src:parede01, alt:'cortina dual-vision'},
//             {id:2, src:parede01, alt:'cortina dual-vision'},
//             {id:3, src:parede01, alt:'cortina dual-vision'}
//         ]
//     },
//     {
//         id:1,
//         title:'cortina-dualvision',
//         serie:[
//             {id:0, src:parede01, alt:'cortina dual-vision'},
//             {id:1, src:parede01, alt:'cortina dual-vision'},
//             {id:2, src:parede01, alt:'cortina dual-vision'},
//             {id:3, src:parede01, alt:'cortina dual-vision'}
//         ]
//     },
//     {
//         id:2,
//         title:'cortina-dualvision',
//         serie:[
//             {id:0, src:parede01, alt:'cortina dual-vision'},
//             {id:1, src:parede01, alt:'cortina dual-vision'},
//             {id:2, src:parede01, alt:'cortina dual-vision'},
//             {id:3, src:parede01, alt:'cortina dual-vision'}
//         ]
//     },    
// ]

