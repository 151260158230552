import React from 'react'
import H1 from '../Common/Typograph/H1'
import Grid4 from '../Common/Layout/Grids/Grid4'
import './Produto.css'
import ContainerFlex from '../Common/Layout/Container/ContainerFlex'
import Sections from '../Sections/Sections'
import { Link } from 'react-router-dom'
import img1 from '../assets/produtos/cortinas/americana/cpa01.jpg'
import img2 from '../assets/produtos/cortinas/romana/cr1.jpg'
import img3 from '../assets/produtos/cortinas/macho/cpm01.jpg'
import img4 from '../assets/produtos/persianas/aluminio/pha01.jpg'
import img5 from '../assets/produtos/persianas/madeira/pm03.jpg'
import img6 from '../assets/produtos/persianas/verticais/pvt05.jpg'
import img7 from '../assets/produtos/papeis-de-parede/foto.jpg'

const Produto = () => {  
  const _api = [
    {
      title:'cortinas',
      to:'cortinas',
      imagens:[img1,img2,img3]
    },
    {
      title:'persianas',
      to:'persianas',
      imagens:[img4,img5,img6]
    },
    {
      title:'papéis de parede',
      to:'papeis-de-parede',
      imagens:[img7]
    }
  ]


  return <>
    <section className='produto-header'>
      <H1 className='text-center'>Produtos</H1>      
    </section>
    <Sections className='produtos'>
      {_api.map( (item, index) => {
        return <div key={index}>
          <h2 className='container'>
            <Link to={item.to}>{item.title} <span>Veja Mais +</span></Link>
          </h2>
          <ContainerFlex className='wrap'>
            {item['imagens'].map( (imagem, index) => {              
              return <Grid4 key={index}>
                <Link to={item.to}>
                  <img src={imagem} alt="produtos"/>
                </Link>
              </Grid4>
            })}                      
          </ContainerFlex>
        </div> 
      })}           
    </Sections>
  </>
}

export default Produto