import img1 from '../components/assets/servicos/hom-box1.jpg'
import img2 from '../components/assets/servicos/hom-box2.jpg'
import img3 from '../components/assets/servicos/hom-box3.jpg'


export const ServicosApi = [
    {
        id:0,
        href:'produtos/cortinas',
        imagem:img1,
        alt:'Cortinas - Decor Selis',
        title:'Cortinas',
        text:'Trabalhamos com os melhores tecidos e acabamento sem igual'
    },
    {
        id:1,
        href:'produtos/papeis-de-parede',
        imagem:img2,
        alt:'Papéis de parede',
        title:'Papéis de parede',
        text:'Aqui você encontra paineis diversos - para aplicação na sala, quarto e até jardim!'
    },
    {
        id:2,
        href:'produtos/persianas',
        imagem:img3,
        alt:'Persianas - Decor Selis',
        title:'Persianas',
        text:'Trabalhamos com as Melhores linhas de Persianas do Mercado.'
    },
]
