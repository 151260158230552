import React from 'react'
import './Sobre.css'
import Sections from '../Sections/Sections'
import ContainerFlex from '../Common/Layout/Container/ContainerFlex'
import H1 from '../Common/Typograph/H1'
import P from '../Common/Typograph/P'
import Grid10 from '../Common/Layout/Grids/Grid10'
import Grid6 from '../Common/Layout/Grids/Grid6'
import Grid5 from '../Common/Layout/Grids/Grid5'
import img1 from '../assets/bg/Imagem1.jpg'
import Carosel from '../Common/Carosel/Carosel'
import { _carosel } from '../../Apis/SlidesApi'

const Sobre = () => {
  return <>
    <section className='sobre-header'>
      <H1 className='text-center'>Decor Sélis</H1>
      <div className="container flex-center">
        <Grid10>
          <P className='text-center'>
              A Decor Sélis apresenta a linha de produtos Duraflor, inspirados no que há de mais moderno no mercado internacional. Pisos Laminados de alta resistência e Pisos LVT, vinílicos de última geração para harmonizar sua residência ou ambientes corporativos, além de uma linha completa de acessórios de acabamento e instalação.
          </P>
        </Grid10>
      </div> 
    </section>

    <Sections>
      <ContainerFlex className='flexalign-center flex-center'>                    
        <Grid6>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/qAffzArkNas" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>                        
            </iframe>
        </Grid6>                    
      </ContainerFlex>
    </Sections>

    <Sections className='sobre-info'>
      <ContainerFlex className='flexalign-center flex-center wrap'>
        <Grid5 className='info-img'>
          <img src={img1} alt="serviços-decorselis" />
        </Grid5>
        <Grid6>
          <div className='info-text'>
            <P>
              Cortinas, Persianas, papéis de parede - nacionais e importados - e reforma de estofados.            
            </P>
            <P>
              Manutenção e Higienização de cortinas e persianas.           
            </P>
            <P>
              Orçamento sem compromisso. Consulte um de nossos representantes!
            </P>
          </div>
        </Grid6>
      </ContainerFlex>
    </Sections>

  {/*
    <Sections className='sobre-carosel'>
      <ContainerFlex className='flex-center wrap'>
        <Grid10>
          <Carosel slides={_carosel}/>          
        </Grid10>        
      </ContainerFlex>
      <ContainerFlex className='flex-center'>
        <Grid10 className='carosel-text'>
          <P>
            
          </P>
        </Grid10>
      </ContainerFlex>
</Sections>   */}
  </>
}

export default Sobre