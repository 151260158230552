import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Hooks/ScrollToTop";
import Cortinas from "./components/Pages/Cortinas";
import Persianas from "./components/Pages/Persianas";
import Home from './components/Pages/Home'
import Pagina404 from './components/Pages/Pagina404'
import Sobre from './components/Pages/Sobre'
import Produto from './components/Pages/Produto'
import Contato from "./components/Pages/Contato"
import Blog from './components/Pages/Blog'
import Papeisparede from './components/Pages/Papeisparede'

function App() {
  return <>
    <BrowserRouter>
      <ScrollToTop />
      <Header className='header' id='header' />
      <Routes>
        <Route path="/" element={<Home />} />        
        <Route path="produtos/*">
          <Route path="/" element={<Produto />} />
          <Route path="cortinas" element={<Cortinas />}/>
          <Route path="persianas" element={<Persianas />}/>
          <Route path="papeis-de-parede" element={<Papeisparede />}/>
          <Route path="*" element={<Pagina404 />}/>
        </Route>                 
        <Route path="sobre" element={<Sobre />} />
        <Route path="blog" element={<Blog />}/>
        <Route path="contato" element={<Contato />}/>
        <Route path="*" element={<Pagina404 />}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  </>
}

export default App;
